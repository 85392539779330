import React, { useState } from 'react';
import Avatar from './components/Avatar';
import useEnvVariable from './hooks/useEnvVariable';

function App() {
  const [inputValue, setInputValue] = useState('');
  const envString = useEnvVariable('REACT_APP_ENV_STRING');

  const handleSubmit = (e) => {
    e.preventDefault();
    alert(`Submitted: ${inputValue}`);
  };

  return (
    <div>
      <h1>Welcome to the Page</h1>
      <h2 data-testid="environmentString" id="envString">{envString}</h2>
      <Avatar size={150} borderColor="red" />
      <br />
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder="Enter something"
        />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default App;