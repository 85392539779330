import { useState, useEffect } from 'react';

const useEnvVariable = (key) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    const envValue = process.env[key];  // Accede a la variable desde .env
    if (envValue) {
      setValue(envValue);
    } else {
      console.warn(`Variable de entorno ${key} no encontrada.`);
    }
  }, [key]);

  return value;
};

export default useEnvVariable;